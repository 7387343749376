import ky from "ky";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL;

class FileApi {
  constructor() {}

  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async listFiles(
    networkUnitId: number,
    pageSize: number,
    offSet: number,
    active?: boolean,
    name?: string
  ): Promise<any[]> {
    const headers = await this.getHeaders();
    const url = new URL(`${API_URL}file/${networkUnitId}/${pageSize}/${offSet}`);
    if (typeof active === "boolean") {
      url.searchParams.set("active", active.toString());
    }
    if (name) {
      url.searchParams.set("name", name);
    }
    try {
      const response = await ky.get(url.toString(), { headers });
      const data: any[] = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao listar arquivos (admin)", error);
      throw error;
    }
  }

  public async getTotalItems(
    networkUnitId: number,
    active?: boolean,
    name?: string
  ): Promise<number> {
    const headers = await this.getHeaders();
    const url = new URL(`${API_URL}file/${networkUnitId}/total-items`);
    if (typeof active === "boolean") {
      url.searchParams.set("active", active.toString());
    }
    if (name) {
      url.searchParams.set("name", name);
    }
    try {
      const response = await ky.get(url.toString(), { headers });
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0 && typeof data[0].count === "number") {
        return data[0].count;
      }
      return 0;
    } catch (error) {
      console.error("Erro ao buscar total de itens (arquivos)", error);
      throw error;
    }
  }

  public async postNewFile(
    Icon: File | null,
    Files: File[] | File,
    Name: string,
    PoolType: string,
    CategoryId: string
  ): Promise<unknown> {
    const formData = new FormData();
    const selectedNetwork = localStorage.getItem("selectedNetwork");
    if (selectedNetwork) {
      formData.append("NetworkUnitId", selectedNetwork.toString());
    } else {
      console.error("NetworkUnitId não encontrado no localStorage.");
    }
    formData.append("Name", Name);
    formData.append("PoolType", PoolType);
    formData.append("CategoryId", CategoryId);
    if (Icon) {
      formData.append("Icon", Icon);
    }
    const fileArray = Array.isArray(Files) ? Files : [Files];
    fileArray.forEach((file) => formData.append("File", file));
    try {
      const headers = await this.getHeaders();
      const data = await ky.post(`${API_URL}file`, {
        body: formData,
        headers,
        timeout: false,
      });
      return data;
    } catch (error) {
      console.error("Erro ao cadastrar novo arquivo", error);
      throw error;
    }
  }

  public async listUsers(): Promise<any[]> {
    const headers = await this.getHeaders("application/json");
    const selectedNetwork = localStorage.getItem("selectedNetwork") || "0";
    try {
      const response = await ky.get(
        `${API_URL}user/investor/network-unit/${selectedNetwork}`,
        { headers }
      );
      const data: any[] = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao listar usuários", error);
      throw error;
    }
  }

  public async updateFileStatus(fileId: number, active: boolean): Promise<void> {
    const headers = await this.getHeaders("application/json");
    try {
      await ky.put(`${API_URL}file/update/${fileId}/${active}`, { headers });
    } catch (error) {
      console.error("Erro ao atualizar o status do arquivo", error);
      throw error;
    }
  }

  public async deleteFile(id: string): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(`${API_URL}file/${id}`, { headers });
      return response;
    } catch (error) {
      console.error("Erro ao deletar arquivo", error);
      throw error;
    }
  }

  public async getFileIconUrl(fileId: number): Promise<{ presignedUrl: string }> {
    const headers = await this.getHeaders("application/json");
    const response = await ky.get(`${API_URL}file/icon/${fileId}`, { headers });
    return await response.json();
  }

  public async getFileFileUrl(fileId: number): Promise<{ presignedUrl: string }> {
    const headers = await this.getHeaders("application/json");
    const response = await ky.get(`${API_URL}file/file/${fileId}`, { headers });
    return await response.json();
  }

  public async editFile(
    fileId: number,
    Icon: File | null,
    Name: string,
    CategoryId: number,
    File: File[]
  ): Promise<void> {
    const formData = new FormData();
    formData.append("Name", Name);
    formData.append("CategoryId", CategoryId.toString());
    if (Icon) {
      formData.append("Icon", Icon);
    }
    File.forEach((file) => {
      formData.append("File", file);
    });
    try {
      const headers = await this.getHeaders();
      await ky.put(`${API_URL}file/${fileId}`, {
        body: formData,
        headers,
      });
    } catch (error) {
      console.error("Erro ao editar arquivo", error);
      throw error;
    }
  }

  public async sendPresing(
    networkId: number,
    path: string,
    expireInMinute: number
  ): Promise<any> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.put(
        `https://investor-v2.api.vegait.com/api/2/File/${networkId}/presing`,
        {
          json: { path, expireInMinute },
          headers,
        }
      );
      return response.json();
    } catch (error) {
      console.error("Erro ao enviar presing", error);
      throw error;
    }
  }

  public async triggerSplitBilling(
    networkId: number,
    icon: string,
    name: string,
    poolType: number,
    filePath: string
  ): Promise<any> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.post(
        `https://investor-v2.api.vegait.com/api/2/Financial/${networkId}/split-billing`,
        {
          json: { icon, name, poolType, filePath },
          headers,
        }
      );

      const text = await response.text();
      return text ? JSON.parse(text) : {};
    } catch (error) {
      console.error("Erro ao acionar split billing", error);
      throw error;
    }
  }  
}

export default new FileApi();
