/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Input from "../../components/Input";
import MultiSelect from "../../components/MultiSelect";
import SelectEnterprise from "../../components/SelectEnterprise";
import { Grid2 } from "@mui/material";
import { useState, useEffect } from "react";
import PageSize from "../../components/Table/PageSize";
import Table from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import { getColumns } from "./columns";
import DeleteModal from "../../components/DeleteModal";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import BenefitsAPI from "../../api/benefits/benefits";
import BenefitDTO from "../../api/benefits/dto/benefit-paginated.dto";
import { Spinner } from "../../components/Spinner";

export default function Benefits() {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(10);
  const [deletingContract, setDeletingContract] = useState<null>(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [benefits, setBenefits] = useState<BenefitDTO>([]);
  const dataEdit = [{ name: "teste", category: 1 }];
  const [loading, setLoading] = useState(false);
  const [selectActive, setSelectActive] = useState<number | undefined>(undefined);

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId ?? undefined);
  };
  
  const statusData = [
    { value: "ativos", label: "Ativos" },
    { value: "inativos", label: "Inativos" },
    { value: "todos", label: "Todos" },
  ];

  const handleMultiSelectChange = (values) => {
    setSelectedValues(values);
  };

  const viewReport = (userParam: unknown) => {
    navigate("/programa-de-fidelidade/visualizar-relatorios", {
      state: { isEdit: true, data: dataEdit },
    });
  };

  const editChannel = (row) => {
    navigate("/programa-de-fidelidade/editar-beneficio", {
      state: { isEdit: true, data: row },
    });
  };

  async function deleteItem(deletingItem) {
    setLoading(true);
    try {

      await BenefitsAPI.deleteBenefit(deletingItem.id);

      setBenefits((prevBenefits) => prevBenefits.filter((benefit) => benefit.id !== deletingItem.id));
      setDeletingContract(null); 
    } catch (error) {
      console.error("Erro ao excluir benefício:", error);
    } finally {
      setLoading(false);
    }
  }
  
  

  const newChannelCreateSchema = z.object({
    title: z.any(),
    responsibleUsers: z.any(),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
  });

  async function handleSubmitForm(data: NewChannelsSchema) {
    setLoading(true); 
    try {
      let active: boolean | string = "all";
      
      if (data.responsibleUsers && data.responsibleUsers.length > 0) {
        
        if (data.responsibleUsers.some((u) => u.value === "todos")) {
          active = "all";
        } else if (data.responsibleUsers.some((u) => u.value === "ativos")) {
          active = true;
        } else if (data.responsibleUsers.some((u) => u.value === "inativos")) {
          active = false;
        }
      }
  
      const result = await BenefitsAPI.getBenefit(
        active,
        data.title || "",
        pageSize,
        (currentPage - 1) * pageSize 
      );
      setBenefits(result);
    } catch (error) {
      console.error("Erro ao buscar benefícios:", error);
    } finally {
      setLoading(false); 
    }
  }

  const handleGetBenefits = async () => {
    if (!selectActive) return; 
    setLoading(true);
  
    try {
        
        const result = await BenefitsAPI.getBenefit(
            "all", 
            "", 
            pageSize, 
            (currentPage - 1) * pageSize 
        );

        setBenefits(result);
        
        const totalItems = result.length;
        const calculatedPages = Math.ceil(totalItems / pageSize);
        setNumberOfPages(calculatedPages || 1); 

    } catch (error) {
        console.error("Erro ao buscar benefícios:", error);
    } finally {
        setLoading(false); 
    }
  };

  const handleClearFilters = () => {
    setSelectedValues([]);
    reset();
    handleGetBenefits(); 
  };

  useEffect(() => {
    if (selectActive) {
      handleGetBenefits();
    }
  }, [selectActive, pageSize, currentPage]);

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full sm:flex-col">
        <div className="w-1/2">
          <Header
            title="Benefícios"
            subTitle="Visualize e gerencie os benefícios que os usuários poderão resgatar."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end  gap-5 w-1/2 sm:w-[100%]">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>

          <div>
            <Button
              title="+ Novo benefício"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() =>
                navigate("/programa-de-fidelidade/cadastrar-beneficio")
              }
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex gap-6 sm:flex-col">
          <div className="w-2/4 sm:w-[100%]">
            <Input
              label="Nome"
              placeholder="Digite o nome do benefício"
              type="text"
              register={register("title")}
            />
          </div>
          <div className="w-2/4 sm:w-[100%]">
            <Controller
              name="responsibleUsers"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  label="Status"
                  data={statusData}
                  onChange={(values: SetStateAction<never[]>) => {
                    field.onChange(values);
                    handleMultiSelectChange(values);
                  }}
                  style={{ width: "100%" }}
                  register={field}
                  errors={errors.responsibleUsers}
                  message="Campo obrigatório"
                  keyField="value"
                  labelField="label"
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button
            title="Limpar"
            color="#F28E00"
            backGroundColor="#FCFCFA"
            borderColor="#E3E7FF"
            borderRadius={8}
            onClick={handleClearFilters}
          />
          <Button
            title="Buscar"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            onClick={handleSubmit(handleSubmitForm)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882]">
            <p>
              <span className="font-bold">{benefits.length}</span> itens
              cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>

        <div className="flex flex-col w-full">
          {loading ? (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <Table
              data={benefits}
              columns={getColumns(viewReport, editChannel, setDeletingContract)}
            />
          )}
        </div>
        
        <Pagination
            pageCount={numberOfPages}
            currentPage={currentPage - 1}
            setPage={(page) => setCurrentPage(page + 1)}
            setPagePrevious={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        />

      </div>
      {deletingContract && (
        <DeleteModal
          itemName={deletingContract.name}
          confirm={() => deleteItem(deletingContract)} 
          cancel={() => setDeletingContract(null)}
        />
      )}
      
    </Grid2>
  );
}
