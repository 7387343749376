import ky from "ky";
import dayjs from "dayjs";
import { StatementResponse } from "./statementModels";

const { REACT_APP_API_URL } = process.env;
const API_URL = REACT_APP_API_URL;

class StatementAPI {
  constructor() {}
  
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async createNewStatement(formData: FormData): Promise<void> {
    try {
      const headers = await this.getHeaders();
      
      await ky.post(`${API_URL}notification`, {
        body: formData,
        headers,
      });
      
    } catch (error) {
      console.error("Erro ao cadastrar comunicado", error);
      throw error;
    }
  }

  
  
  
  private async uploadFile(file: File, presignedUrl: string): Promise<void> {
    try {
        await ky.put(presignedUrl, {
        body: file, 
      });
    } catch (error) {
      console.error("Error during file upload:", error);
      throw error;
    }
  }

  public async listStatement(payload: {
    networkUnitId: number;
    pageSize: number;
    offSet: number;
    poolType?: number; 
    title?: string;
    startDate?: string;
    endDate?: string;
}): Promise<StatementResponse[] | undefined> {
    const headers = await this.getHeaders("application/json");

    const queryParams = new URLSearchParams({
        NetworkUnitId: payload.networkUnitId.toString(),
        PageSize: payload.pageSize.toString(),
        OffSet: payload.offSet.toString(),
    });

    if (payload.poolType !== undefined) {
        queryParams.append("PoolType", payload.poolType.toString());
    }
    if (payload.title) {
        queryParams.append("Title", payload.title);
    }
    if (payload.startDate) {
        queryParams.append("StartDate", payload.startDate);
    }
    if (payload.endDate) {
        queryParams.append("EndDate", payload.endDate);
    }

    try {
        const response = await ky
            .get(`${API_URL}notification/admin/list-notification?${queryParams}`, {
                headers,
            })
            .json<StatementResponse[]>();
        return response;
    } catch (error) {
        console.error("Erro ao listar comunicados", error);
        return undefined;
    }
}

  public async deleteStatement(id: string): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `${API_URL}notification/${id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("Erro ao deletar comunicado", error);
    }
  }

  public async editStatement(
    id: string,
    Schedule: string,
    Name: string,
    File: File | null,
    UserIds: string[]
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("Schedule", dayjs(Schedule).format('YYYY-MM-DDTHH:mm:ssZ'));
    formData.append("Name", Name);
    UserIds.forEach((id) => formData.append("UserIds[]", id));

    if (File) {
      formData.append("File", File);
    }

    try {
      const headers = await this.getHeaders();
      headers["Content-Type"] = "multipart/form-data";
      const data = await ky.put(
        `${API_URL}notification/${id}`,
        { body: formData, headers }
      );
      return data;
    } catch (error) {
      console.error("Erro ao editar comunicado", error);
      throw error;
    }
  }
}

export default new StatementAPI();
