import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header'; 
import SelectEnterprise from '../../components/SelectEnterprise';
import UserApi from '../../api/user/user';

import IconMessage from "../../assets/icons/message-icon.svg";
import UserTie from "../../assets/icons/user-tie.svg";
import IconCommunity from "../../assets/icons/fone-icon.svg";
import IconFile from "../../assets/icons/icon-file.svg";
import IconPoints from "../../assets/icons/icon-benefits.svg";
import User from "../../assets/icons/icon-user.svg";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const modules = [
  { title: "Atendimento", url: "/Mensagens", imageUrl: IconMessage },
  { title: "Proprietários", url: "/proprietarios", imageUrl: UserTie },
  { title: "Comunicados", url: "/comunicados", imageUrl: IconCommunity },
  { title: "Documentos", url: "/arquivos", imageUrl: IconFile },
  { title: "Programa de fidelidade", url: "/Pontos", imageUrl: IconPoints },
  { title: "Gestão de usuários", url: "/gestao-usuarios", imageUrl: User },
];

const actions = modules.map((module) => ({
  title: module.title,
  href: module.url,
  iconSrc: module.imageUrl,
  iconForeground: 'text-white',
  iconBackground: 'bg-[#F28E00]',
  description: `Acesse o módulo ${module.title} para gerenciar suas funções.`,
}));

const Home: React.FC = () => {
  const [selectActive, setSelectActive] = useState<number | null>(null);
  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await UserApi.listUserData();
        if (response) setUserName(response.Name);
      } catch (error) {
        console.error("Erro ao buscar nome do usuário", error);
      }
    };
    fetchUserData();
  }, []);

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    if (selectActive !== selectedHotelId) {
      setSelectActive(selectedHotelId);
    }
  };

  useEffect(() => {
    const networkUnitId = localStorage.getItem("selectedNetwork");
    if (networkUnitId) {
      setSelectActive(Number(networkUnitId));
    }
  }, []);

  return (
    <div className="p-8">
      <div className="flex w-full mb-8">
        <div className="w-1/2">
          <Header
            title={`Bem-vindo${userName ? `, ${userName}` : ""}!`}
            subTitle="Esta é a home do painel administrativo."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
        </div>
      </div>

      <section>
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow-sm sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {actions.map((action, actionIdx) => (
            <div key={action.title} 
              
                className={classNames(
                  actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                  actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                  actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                  actionIdx === actions.length - 1 ? 'rounded-br-lg rounded-bl-lg sm:rounded-bl-none' : '',
                  'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-[#2E406B] focus-within:ring-inset'
                )}
              >
                <div>
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      'inline-flex rounded-lg p-3 ring-4 ring-white'
                    )}
                  >
                    <img src={action.iconSrc} alt={action.title} className="h-6 w-6" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3>
                    <Link to={action.href} className="relative block focus:outline-none text-base font-bold text-[#2E406B] hover:text-blue-800">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {action.title}
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {action.description}
                  </p>
                </div>
                <span
                  aria-hidden="true"
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                >
                  <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
