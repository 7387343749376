import { FC, useState } from "react";
import * as Styled from "./styles";
import CloseIcon from "../../assets/icons/close-icon.svg";
import Button from "../Button";
import { Spinner } from "../Spinner";

interface IProps {
  itemName: string;
  confirm: () => Promise<void>;
  cancel: () => void;
}

const DeleteModal: FC<IProps> = ({ itemName, confirm, cancel }: IProps) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await confirm();
    setIsDeleting(false);
  };

  return (
    <Styled.Container data-testid="DeleteModal">
      <Styled.Modal>
        <Styled.CoontainerHeader>
          <Styled.Close onClick={cancel} disabled={isDeleting}>
            <img src={CloseIcon} />
          </Styled.Close>
          <Styled.ContainerTitle>
            <p>Atenção</p>
          </Styled.ContainerTitle>
        </Styled.CoontainerHeader>

        <Styled.ContainerDescription>
          <p>
            Deseja prosseguir com a exclusão do canal <span>“{itemName}”</span>?
          </p>
        </Styled.ContainerDescription>

        <Styled.OptionsContainer>
          <Button
            title="Cancelar"
            backGroundColor="white"
            color="#8A97B1"
            borderRadius={8}
            onClick={cancel}
            disabled={isDeleting}
          />

          <Button
            title={isDeleting ? <Spinner /> : "Excluir"}
            backGroundColor="#D42A2A"
            color="#fff"
            borderRadius={8}
            onClick={handleDelete}
            disabled={isDeleting}
          />
        </Styled.OptionsContainer>
      </Styled.Modal>
    </Styled.Container>
  );
};

export default DeleteModal;
