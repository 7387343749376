import { ButtonProps } from "./types";

export default function Button({ title, backGroundColor, color, borderRadius, borderColor, onClick, form, type, disabled }: ButtonProps) {
    const buttonStyle = {
        backgroundColor: backGroundColor,
        color: color,
        borderRadius: `${borderRadius}px`,
        borderColor: `${borderColor}`,
        padding: '10px 20px',
        border: '1px solid',
        cursor: disabled ? "not-allowed" : "pointer",
        outline: 'none',
        opacity: disabled ? 0.6 : 1,
    };

    return (
        <button style={buttonStyle} onClick={onClick} form={form} type={type} disabled={disabled}>
            {title}
        </button>
    );
}
