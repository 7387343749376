import { useEffect, useState, useRef, useCallback } from "react";
import React from "react";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import SelectEnterprise from "../../../components/SelectEnterprise";
import { ChatBubbleOutline, MoreVert, Send } from "@mui/icons-material";
import MessagesAPI from "../../../api/messages/messages";
import { Spinner } from "../../../components/Spinner";
import FilterModal from "../../../components/FilterModal/FilterModal";
import { ChatMessageResponse } from "../../../api/messages/messages";
import arrow from "../../../assets/icons/arrow-back.svg";

const { REACT_APP_APPSYNC_HOST } = process.env;
const { REACT_APP_APPSYNC_REALTIME_HOST } = process.env;
const { REACT_APP_APPSYNC_API_KEY } = process.env;

const APPSYNC_HOST = REACT_APP_APPSYNC_HOST ;
const APPSYNC_REALTIME_HOST = REACT_APP_APPSYNC_REALTIME_HOST ;
const APPSYNC_API_KEY = REACT_APP_APPSYNC_API_KEY;


const onMessageSubscription = /* GraphQL */ `
  subscription OnMessage($chatThreadsId: String!, $userId: Int) {
    onMessage(chatThreadsId: $chatThreadsId, userId: $userId) {
      userId
      chatThreadsId
      createdAt
      message
      file
      chatTopicId
      chatTopicTitle
    }
  }
`;

const onChatUpdateAdminSubscription = /* GraphQL */ `
  subscription OnChatUpdateAdmin {
    onChatUpdateAdmin {
      active
      chatThreadsId
      chatTopicId
      chatTopicTitle
      networkUnitId
      status
      userId
      ownerId
    }
  }
`;

type Conversation = {
  ChatThreadsId: number;
  ChatTopicId: number;
  Title: string;
  Active?: boolean;
  OwnerUserId?: number;
  Name: string;
  LatestCreatedAt: string;
  Message: string;
  hasNewMessages?: boolean;
};

type MessageType = {
  localId?: string; 
  Message: string;
  Time: string;
  IsSender: boolean;
  sending?: boolean; 
};

export default function NewChannel() {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [message, setMessage] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [selectActive, setSelectActive] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [availableTitles, setAvailableTitles] = useState<string[]>([]);
  const [selectedFilterTitles, setSelectedFilterTitles] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [sidebarUpdates, setSidebarUpdates] = useState<number>(0);
  const userId = Number(localStorage.getItem("userId"));
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, []);

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const truncateMessage = (msg: string | null | undefined, maxLength = 50) => {
    if (!msg) return "";
    if (msg.length <= maxLength) return msg;
    return msg.slice(0, maxLength) + "...";
  };

  const loadConversations = async () => {
    const networkUnitId = localStorage.getItem("selectedNetwork");
    const userIdLS = localStorage.getItem("userId");
    if (!networkUnitId) {
      console.error("NetworkUnitId não encontrado.");
      return;
    }

    setIsLoading(true);
    try {
      const data = await MessagesAPI.listAdminChannels(Number(networkUnitId), Number(userIdLS));
      const adjustedData: Conversation[] = data.map((conv: any) => ({
        ChatThreadsId: conv.Id,
        ChatTopicId: conv.ChatTopicId,
        Title: conv.Title as string,
        Active: conv.Active ?? true,
        OwnerUserId: conv.OwnerUserId,
        Name: conv.Name as string,
        LatestCreatedAt: conv.LatestCreatedAt as string,
        Message: conv.Message as string,
      }));

      const sortedData = adjustedData.sort(
        (a, b) => new Date(b.LatestCreatedAt).getTime() - new Date(a.LatestCreatedAt).getTime()
      );

      setConversations(sortedData || []);
      const uniqueTitles = Array.from(new Set(sortedData.map((c) => c.Title)));
      setAvailableTitles(uniqueTitles);
    } catch (error) {
      console.error("Erro ao carregar conversas:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMessages = async (chatThreadsId: number, offset: number, prepend = false) => {
    try {
      const container = messagesContainerRef.current;
      let oldScrollHeight = 0;
      if (container && prepend) {
        oldScrollHeight = container.scrollHeight;
      }

      const data = await MessagesAPI.getPaginatedMessages(chatThreadsId, offset);
      if (!data || data.length === 0) {
        setHasMore(false);
        setIsLoadingMoreMessages(false);
        return;
      }

      const reversedData = (data as ChatMessageResponse[]).reverse();

      const formattedMessages = reversedData.map((msg: ChatMessageResponse) => ({
        Message: msg.Message,
        Time: formatTime(msg.CreatedAt),
        IsSender: msg.UserId === userId,
      }));

      setMessages((prev) =>
        prepend ? [...formattedMessages, ...prev] : [...prev, ...formattedMessages]
      );

      if (!prepend) {
        setTimeout(() => {
          scrollToBottom();
        }, 0);
      } else if (container) {
        setTimeout(() => {
          const newScrollHeight = container.scrollHeight;
          container.scrollTop = newScrollHeight - oldScrollHeight;
        }, 0);
      }

      setIsLoadingMoreMessages(false);
    } catch (error) {
      console.error("Erro ao carregar mensagens:", error);
      setIsLoadingMoreMessages(false);
    }
  };

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId);
  };

  const handleSelectConversation = async (conversation: Conversation) => {
    if (!conversation) {
      console.error("Conversa inválida selecionada!");
      return;
    }

    setSelectedConversation(conversation);
    setMessages([]);
    setPage(1);
    setHasMore(true);
    setIsLoadingMessages(true);

    setConversations((prev) =>
      prev.map((conv) =>
        conv.ChatThreadsId === conversation.ChatThreadsId
          ? { ...conv, hasNewMessages: false }
          : conv
      )
    );

    await loadMessages(conversation.ChatThreadsId, 1);
    setIsLoadingMessages(false);
  };

  const handleSendMessage = async () => {
    if (!message.trim() || !selectedConversation || isSending) {
      console.error("Mensagem vazia ou nenhuma conversa selecionada!");
      return;
    }

    setIsSending(true);
    const chatThreadsId = selectedConversation.ChatThreadsId;

    const localId = `temp-${Date.now()}`;

    const newOptimisticMessage: MessageType = {
      localId,
      Message: message,
      Time: formatTime(new Date().toISOString()),
      IsSender: true,
      sending: true,
    };

    setMessages((prev) => [...prev, newOptimisticMessage]);
    setMessage("");

    setTimeout(() => {
      scrollToBottom();
    }, 0);

    try {
      const response = await MessagesAPI.sendMessage(
        chatThreadsId,
        message,
        userId,
        selectedConversation.ChatTopicId
      );

      const { Id, CreatedAt } = response || {};

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.localId === localId
            ? {
                ...msg,
                sending: false,
                Time: CreatedAt ? formatTime(CreatedAt) : msg.Time,
              }
            : msg
        )
      );
    } catch (error) {
      console.error("Erro ao enviar mensagem:", error);

      setMessages((prevMessages) => prevMessages.filter((m) => m.localId !== localId));
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    const networkUnitId = localStorage.getItem("selectedNetwork");
    if (networkUnitId) {
      setSelectActive(Number(networkUnitId));
    }
  }, []);

  useEffect(() => {
    if (selectActive !== null) {
      loadConversations();
      setSelectedConversation(null);
      setMessages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectActive]);

  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (!container || isLoading || !hasMore || isLoadingMoreMessages) return;

    if (container.scrollTop === 0 && selectedConversation) {
      const nextPage = page + 1;
      setPage(nextPage);
      setIsLoadingMoreMessages(true);
      loadMessages(selectedConversation.ChatThreadsId, nextPage, true);
    }
  };

  const handleFilterApply = (selectedTitles: string[]) => {
    setSelectedFilterTitles(selectedTitles);
  };

  let filteredConversations = selectedFilterTitles.length > 0
    ? conversations.filter((c) => selectedFilterTitles.includes(c.Title))
    : conversations;

  if (searchTerm.trim() !== "") {
    filteredConversations = filteredConversations.filter((c) =>
      c.Name && c.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  function encodeAppSyncCredentials() {
    const creds = {
      host: sanitizeUrl(APPSYNC_HOST),
      "x-api-key": APPSYNC_API_KEY,
    };
    return window.btoa(JSON.stringify(creds));
  }

  function sanitizeUrl(url: any): any {
    let sanitized = url.replace(/^https?:\/\//, '');
    sanitized = sanitized.replace(/\/graphql$/, '');
    return sanitized;
  }

  function getWebsocketUrl() {
    const header = encodeAppSyncCredentials();
    const payload = window.btoa(JSON.stringify({}));
    return `${APPSYNC_REALTIME_HOST}?header=${header}&payload=${payload}`;
  }

  useEffect(() => {
    if (!selectedConversation) return;

    const wsUrl = getWebsocketUrl();
    const websocket = new WebSocket(wsUrl, ["graphql-ws"]);

    websocket.onopen = () => {
      websocket.send(JSON.stringify({ type: "connection_init" }));
    };

    websocket.onmessage = (event) => {
      const messageData = JSON.parse(event.data);

      switch (messageData.type) {
        case "connection_ack": {
          const subscriptionId =
            typeof crypto.randomUUID === "function"
              ? crypto.randomUUID()
              : Math.random().toString(36).substring(2);

          const subscribeMessage = {
            id: subscriptionId,
            type: "start",
            payload: {
              data: JSON.stringify({
                query: onMessageSubscription,
                variables: {
                  chatThreadsId: selectedConversation.ChatThreadsId.toString(),
                  userId: userId,
                },
              }),
              extensions: {
                authorization: {
                  "x-api-key": APPSYNC_API_KEY,
                  host: sanitizeUrl(APPSYNC_HOST),
                },
              },
            },
          };
          websocket.send(JSON.stringify(subscribeMessage));
          break;
        }

        case "start_ack":
          break;

        case "data": {
          const newMessage = messageData.payload.data.onMessage;
          setMessages((prev) => {
            const exists = prev.some(
              (m) =>
                m.Message === newMessage.message &&
                m.Time === formatTime(newMessage.createdAt)
            );
            if (exists) {
              return prev;
            }
            return [
              ...prev,
              {
                Message: newMessage.message,
                Time: formatTime(newMessage.createdAt),
                IsSender: newMessage.userId === userId,
              },
            ];
          });
          scrollToBottom();
          break;
        }

        case "error":
          console.error("Erro na subscription (onMessage):", messageData);
          break;

        default:
          break;
      }
    };

    websocket.onerror = (error) => {
      console.error("Erro no WebSocket (onMessage):", error);
    };

    return () => {
      websocket.close();
    };
  }, [selectedConversation, userId, scrollToBottom]);

  useEffect(() => {
    const wsUrl = getWebsocketUrl();
    const websocketAdmin = new WebSocket(wsUrl, ["graphql-ws"]);

    websocketAdmin.onopen = () => {
      websocketAdmin.send(JSON.stringify({ type: "connection_init" }));
    };

    websocketAdmin.onmessage = (event) => {
      const messageData = JSON.parse(event.data);

      switch (messageData.type) {
        case "connection_ack": {
          const subscriptionId =
            typeof crypto.randomUUID === "function"
              ? crypto.randomUUID()
              : Math.random().toString(36).substring(2);

          const subscribeMessage = {
            id: subscriptionId,
            type: "start",
            payload: {
              data: JSON.stringify({
                query: onChatUpdateAdminSubscription,
                variables: {},
              }),
              extensions: {
                authorization: {
                  "x-api-key": APPSYNC_API_KEY,
                  host: sanitizeUrl(APPSYNC_HOST),
                },
              },
            },
          };

          websocketAdmin.send(JSON.stringify(subscribeMessage));
          break;
        }

        case "start_ack":
          break;

        case "data": {
          const chatEvent = messageData.payload.data.onChatUpdateAdmin;
          if (!chatEvent) return;

          if (chatEvent.status === "CHAT_CREATED") {
            setConversations((prev) => {
              const exists = prev.some(
                (c) => c.ChatThreadsId === Number(chatEvent.chatThreadsId)
              );
              if (exists) return prev;
              const newConversation: Conversation = {
                ChatThreadsId: Number(chatEvent.chatThreadsId),
                ChatTopicId: chatEvent.chatTopicId,
                Title: chatEvent.chatTopicTitle || "",
                Active: chatEvent.active,
                OwnerUserId: chatEvent.ownerId,
                Name: "Novo usuário", 
                LatestCreatedAt: new Date().toISOString(),
                Message: "",
                hasNewMessages: true,
              };
              return [newConversation, ...prev];
            });
            setSidebarUpdates((prev) => prev + 1);
          } else if (chatEvent.status === "MESSAGE_UPDATED") {
            setConversations((prev) => {
              return prev.map((conv) => {
                if (conv.ChatThreadsId === Number(chatEvent.chatThreadsId)) {
                  const isCurrent =
                    selectedConversation?.ChatThreadsId === Number(chatEvent.chatThreadsId);
                  return {
                    ...conv,
                    LatestCreatedAt: new Date().toISOString(),
                    hasNewMessages: !isCurrent,
                  };
                }
                return conv;
              });
            });
            
            if (selectedConversation?.ChatThreadsId !== Number(chatEvent.chatThreadsId)) {
              setSidebarUpdates((prev) => prev + 1);
            }
          }
          break;
        }

        case "error":
          console.error("Erro na subscription (onChatUpdateAdmin):", messageData);
          break;

        default:
          break;
      }
    };

    websocketAdmin.onerror = (error) => {
      console.error("Erro no WebSocket (onChatUpdateAdmin):", error);
    };

    return () => {
      websocketAdmin.close();
    };
  }, [selectedConversation]);

  function handleSidebarRefresh() {
    setSidebarUpdates(0);
    loadConversations();
  }

  return (
    <div className="flex flex-col w-full h-screen bg-gray-50 p-8 overflow-hidden">
      <div className="flex w-full mb-8">
        <div className="w-1/2">
          <Header
            title="Mensagens"
            subTitle="Visualize um histórico de conversa e responda aos usuários."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
        </div>
      </div>

      <div className="flex gap-8 h-full overflow-hidden">
        <aside className="w-1/3 bg-white shadow-lg rounded-lg p-6 flex flex-col h-full overflow-hidden">
          <div className="mb-6">
            <Input
              label=""
              placeholder="Pesquisar"
              type="text"
              icon={true}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <button
            className="flex items-center gap-2 border border-orange-400 bg-gray-50 text-orange-600 px-4 py-2 rounded-lg mb-6"
            onClick={() => setIsFilterModalOpen(true)}
          >
            <span className="text-sm font-medium">
              {`Filtrar canais${
                selectedFilterTitles.length > 0 ? ` (${selectedFilterTitles.length})` : ""
              }`}
            </span>
          </button>

          {sidebarUpdates > 0 && (
            <div
              className="bg-blue-100 text-blue-800 px-3 py-2 rounded-md mb-4 text-sm flex justify-between items-center cursor-pointer"
              onClick={handleSidebarRefresh}
            >
              <span>{sidebarUpdates} nova(s) atualização(ões) no chat</span>
              <img
                src={arrow}
                alt="Seta para cima"
                className="w-4 h-4 transform rotate-90"
              />
            </div>
          )}

          {isLoading ? (
            <div className="flex justify-center items-center flex-1">
              <Spinner />
            </div>
          ) : filteredConversations.length === 0 ? (
            <div className="flex flex-col justify-center items-center flex-1 text-center">
              <ChatBubbleOutline style={{ fontSize: 40, color: "#9ca3af" }} className="mb-4" />
              <h2 className="text-sm text-gray-800">Você ainda não iniciou nenhum chat</h2>
            </div>
          ) : (
            <div className="flex-1 overflow-y-auto">
              {filteredConversations.map((conversation) => (
                <div
                  key={conversation.ChatThreadsId}
                  className={`p-4 border-b cursor-pointer hover:bg-gray-50 transition-colors ${
                    selectedConversation?.ChatThreadsId === conversation.ChatThreadsId
                      ? "bg-gray-100"
                      : "bg-white"
                  }`}
                  onClick={() => handleSelectConversation(conversation)}
                >
                  <div className="flex justify-between items-center mb-1">
                    <div className="flex items-center gap-2">
                      <span className="font-bold text-gray-900">
                        {conversation.Name || "Usuário"}
                      </span>
                      <span className="text-xs bg-gray-100 text-gray-600 px-2 py-[2px] rounded-full">
                        {conversation.Title}
                      </span>
                      {conversation.hasNewMessages && (
                        <span className="text-xs bg-red-500 text-white px-2 py-[2px] rounded-full">
                          Novas mensagens
                        </span>
                      )}
                    </div>
                    <span className="text-sm text-gray-500">
                      {formatTime(conversation.LatestCreatedAt)}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600">
                    {conversation.Message
                      ? truncateMessage(conversation.Message, 50)
                      : "Nenhuma mensagem enviada"}
                  </p>
                </div>
              ))}
            </div>
          )}
        </aside>

        {/* ÁREA DE MENSAGENS */}
        <section className="flex-1 bg-white shadow-lg rounded-lg p-6 flex flex-col h-full overflow-hidden">
          {selectedConversation && !isLoading ? (
            <>
              <div className="flex justify-between items-center border-b pb-4 mb-4 flex-none">
                <div className="flex items-center gap-2">
                  <h2 className="font-bold text-gray-800">{selectedConversation?.Name}</h2>
                  <span className="text-xs bg-gray-100 text-gray-600 px-2 py-[2px] rounded-full">
                    {selectedConversation?.Title}
                  </span>
                </div>
                <MoreVert className="text-gray-700 cursor-pointer" />
              </div>

              {isLoadingMessages ? (
                <div className="flex-1 flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <div
                  className="relative flex-1 overflow-y-auto space-y-4 mb-4"
                  ref={messagesContainerRef}
                  onScroll={handleScroll}
                >
                  {isLoadingMoreMessages && (
                    <div className="flex justify-center items-center my-2">
                      <Spinner />
                    </div>
                  )}
                  {messages.map((messageObj, index) => (
                    <div
                      key={messageObj.localId ?? index}
                      className={`flex ${
                        messageObj.IsSender ? "justify-end" : "justify-start"
                      }`}
                    >
                      <div
                        className={`relative p-3 rounded-lg max-w-[75%] ${
                          messageObj.IsSender
                            ? "bg-[#2E406B] text-white"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        <p className="text-sm">{messageObj.Message}</p>
                        <div className="flex items-center justify-end gap-1 mt-1">
                          <span className="text-xs text-gray-400">{messageObj.Time}</span>
                          {messageObj.sending && (
                            <span className="text-[10px] text-gray-400 italic">(Enviando...)</span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <footer className="flex items-center gap-2 bg-gray-50 px-4 py-3 flex-none">
                <div className="flex items-center flex-1 bg-white border border-gray-300 rounded-full px-4 py-2">
                  <Input
                    label=""
                    placeholder="Digite uma mensagem"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleSendMessage}
                  className="bg-[#2E406B] p-3 rounded-full flex items-center justify-center shadow-md text-white"
                  disabled={isSending}
                >
                  <Send />
                </button>
              </footer>
            </>
          ) : (
            !isLoading && (
              <div className="flex flex-col justify-center items-center text-center flex-1">
                <ChatBubbleOutline style={{ fontSize: 60, color: "#9ca3af" }} className="mb-6" />
                <h2 className="text-lg font-bold text-gray-800 mb-2">Boas-vindas</h2>
                <p className="text-sm text-gray-600">
                  Para começar a enviar mensagens, primeiro selecione uma conversa na lista ao lado.
                </p>
              </div>
            )
          )}

          {isLoading && (
            <div className="flex flex-col justify-center items-center text-center flex-1">
              <Spinner />
            </div>
          )}
        </section>
      </div>

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        titles={availableTitles}
        onFilterApply={handleFilterApply}
      />
    </div>
  );
}
