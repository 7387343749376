import iconjiah from "../../assets/icons/jiah-logo.png";

export default function NotAuthorized() {
  return (
    <>
      <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-6 lg:px-8">
          <div className="flex shrink-0 justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">JIAH</span>
              <img
                alt="Logo da Empresa"
                src={iconjiah}
                className="h-8 w-auto"
              />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-[#2E406B]">Acesso Negado</p>
              <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Você não tem permissão <br/> para acessar esta página.
              </h1>
              <p className="mt-6 text-base text-gray-500">
                Por favor, contate a administração responsável para obter mais informações.
              </p>
              <div className="mt-6">
                <a
                  href="/"
                  className="inline-flex items-center justify-center rounded-lg bg-[#F28E00] px-6 py-3 text-base font-medium text-white hover:text-gray-500 shadow-sm hover:bg-[#d97706] transition duration-300"
                >
                  <span className="font-bold">Voltar para a Home</span>
                  <span aria-hidden="true" className="ml-2">→</span>
                </a>
              </div>

            </div>
          </div>
        </main>
        <footer className="mx-auto w-full max-w-7xl shrink-0 px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <a
              href="/contato"
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              Contate a administração
            </a>
            <span
              aria-hidden="true"
              className="inline-block border-l border-gray-300"
            />
            <a
              href="/status"
              className="text-sm font-medium text-gray-500 hover:text-gray-600"
            >
              Status do Sistema
            </a>
          </nav>
        </footer>
      </div>
    </>
  );
}
