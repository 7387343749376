import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const {
  REACT_APP_APPSYNC_HOST,
  REACT_APP_APPSYNC_REALTIME_HOST,
  REACT_APP_APPSYNC_API_KEY,
} = process.env;

function sanitizeUrl(url: string): string {
  let sanitized = url.replace(/^https?:\/\//, '');
  sanitized = sanitized.replace(/\/graphql$/, '');
  return sanitized;
}

function encodeAppSyncCredentials(): string {
  const creds = {
    host: sanitizeUrl(REACT_APP_APPSYNC_HOST || ""),
    "x-api-key": REACT_APP_APPSYNC_API_KEY,
  };
  return window.btoa(JSON.stringify(creds));
}

function getWebsocketUrl(): string {
  const header = encodeAppSyncCredentials();
  const payload = window.btoa(JSON.stringify({}));
  return `${REACT_APP_APPSYNC_REALTIME_HOST}?header=${header}&payload=${payload}`;
}

const ON_USER_BACKGROUND_UPDATES = `
  subscription onUserBackgroundUpdates($userId: Int!) {
    onUserBackgroundUpdates(userId: $userId) {
      userId
      networkUnitId
      statusCode
      messageKey
      message
      traceId
    }
  }
`;

const BackgroundProcessingNotification: React.FC = () => {
  const userId = Number(localStorage.getItem("userId"));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const wsUrl = getWebsocketUrl();
    const websocket = new WebSocket(wsUrl, ["graphql-ws"]);

    websocket.onopen = () => {
      websocket.send(JSON.stringify({ type: "connection_init" }));
    };

    websocket.onmessage = (event) => {
      const messageData = JSON.parse(event.data);

      switch (messageData.type) {
        case "connection_ack": {
          const subscriptionId =
            typeof crypto.randomUUID === "function"
              ? crypto.randomUUID()
              : Math.random().toString(36).substring(2);

          const subscribeMessage = {
            id: subscriptionId,
            type: "start",
            payload: {
              data: JSON.stringify({
                query: ON_USER_BACKGROUND_UPDATES,
                variables: { userId },
              }),
              extensions: {
                authorization: {
                  "x-api-key": REACT_APP_APPSYNC_API_KEY,
                  host: sanitizeUrl(REACT_APP_APPSYNC_HOST || ""),
                },
              },
            },
          };

          websocket.send(JSON.stringify(subscribeMessage));
          break;
        }
        case "data": {
          const eventData = messageData.payload.data.onUserBackgroundUpdates;
          if (
            eventData &&
            eventData.statusCode === 200 &&
            eventData.messageKey === "SPLIT_BILLING"
          ) {
            toast.success("Os boletos foram todos carregados", {
              position: "top-right",
              autoClose: false, 
              closeOnClick: true,
              draggable: true,
              theme: "colored",
              onClick: () => {
                if (location.pathname === "/arquivos") {
                  window.location.reload();
                } else {
                  navigate("/arquivos");
                }
              },
            });
          }
          break;
        }
        case "error":
          console.error(
            "Erro na subscription (onUserBackgroundUpdates):",
            messageData.payload.errors
          );
          break;
        default:
          break;
      }
    };

    websocket.onerror = (error) => {
      console.error("Erro no WebSocket (onUserBackgroundUpdates):", error);
    };

    return () => {
      websocket.close();
    };
  }, [userId, navigate, location]);

  return null;
};

export default BackgroundProcessingNotification;
