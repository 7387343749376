import ReactPaginate from 'react-paginate';
import styled, { IStyledComponent } from 'styled-components';

export const Pagination = styled.div``;

const paginate = ReactPaginate as unknown as IStyledComponent<"web">;

export const MyPaginate = styled(paginate).attrs({
  activeClassName: 'active',
})`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding-left: 0px;
  flex-wrap: wrap;

  li {
    margin-top: 20px;
  }

  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    cursor: pointer;
    border: none;
    color: #8a97b1;
    margin-top: -3px;
  }

  li.previous {
    padding-right: 20px;
    white-space: nowrap;
  }

  li.next {
    padding-left: 20px;
    white-space: nowrap;
  }

  li.previous a,
  li.next a {
    background: #f6faff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #8a97b1;
    opacity: 1;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  li.break a {
    border: none;
  }

  /* Página ativa */
  li.active a {
    background-color: #f28e00; 
    color: #fff;              
    border: none;            
    min-width: 32px;
    text-align: center;
  }

  /* Desabilitado */
  li.disabled a {
    color: grey;
  }

  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

export const Page = styled.div`
    display: flex;
    margin-top: 20px;
    width: 200px;
`;

export const TextGoPage = styled.div`
    color: #8a97b1;
    padding-right: 16px;
`;

export const Input = styled.input.attrs(() => ({
    type: 'number',
}))`
    border: 1px solid #d4e6ff;
    border-radius: 4px 0px 0px 4px;
    width: 32px;
    height: 32px;
    text-align: center;
    color: #7f7f7f;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
`;

export const ButtonGo = styled.button`
    cursor: pointer;
    background: rgb(242, 142, 0);
    border-radius: 0px 4px 4px 0px;
    border: none;
    width: 33px;
    height: 32px;
    color: #fff4c2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
`;
