// AppRoutes.tsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../components/Layout/layout';
import Messages from '../pages/Channels/Messages';
import Channels from '../pages/Channels/Channels';
import NewChannel from '../pages/Channels/Channels/NewChannel';
import NewStatement from '../pages/Statement/NewStatement';
import Statement from '../pages/Statement';
import FilesPage from '../pages/Files';
import NewFile from '../pages/Files/NewFile';
import Benefits from '../pages/Benefits';
import NewBenefit from '../pages/Benefits/NewBenefit';
import ViewReport from '../pages/Benefits/ViewReport';
import Points from '../pages/Benefits/Points';
import NewPoints from '../pages/Benefits/Points/NewPoints';
import Login from '../components/Login';
import PrivateRoute from './privateRoute';
import CategoriesPage from '../pages/Files/NewCategory';
import Owners from '../pages/Owners';
import UserManagement from '../pages/UserManagement';
import NewUser from '../pages/UserManagement/NewUser';
import ProtectedRoute from '../components/ProtectedRoute';
import NotAuthorized from '../components/NotAuthorized';
import Home from '../pages/Home';


function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
          
          {/* Atendimento */}
          <Route 
            path="/Mensagens" 
            element={
              <ProtectedRoute requiredModuleId={101}>
                <Messages />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/Canais" 
            element={
              <ProtectedRoute requiredModuleId={102}>
                <Channels />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/novo-canal" 
            element={
              <ProtectedRoute requiredModuleId={102}>
                <NewChannel />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/editar-canal" 
            element={
              <ProtectedRoute requiredModuleId={102}>
                <NewChannel />
              </ProtectedRoute>
            } 
          />

          {/* Comunicados */}
          <Route 
            path="/cadastrar-comunicado" 
            element={
              <ProtectedRoute requiredModuleId={3}>
                <NewStatement />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/comunicados" 
            element={
              <ProtectedRoute requiredModuleId={3}>
                <Statement />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/editar-comunicado" 
            element={
              <ProtectedRoute requiredModuleId={3}>
                <NewStatement />
              </ProtectedRoute>
            } 
          />

          {/* Documentos */}
          <Route 
            path="/arquivos" 
            element={
              <ProtectedRoute requiredModuleId={401}>
                <FilesPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/arquivos/cadastrar-arquivo" 
            element={
              <ProtectedRoute requiredModuleId={401}>
                <NewFile />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/arquivos/editar-arquivo" 
            element={
              <ProtectedRoute requiredModuleId={401}>
                <NewFile />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/arquivos/categorias" 
            element={
              <ProtectedRoute requiredModuleId={402}>
                <CategoriesPage />
              </ProtectedRoute>
            } 
          />

          {/* Programa de Fidelidade */}
          {/* Benefícios */}
          <Route 
            path="/programa-de-fidelidade" 
            element={
              <ProtectedRoute requiredModuleId={502}>
                <Benefits />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/programa-de-fidelidade/cadastrar-beneficio" 
            element={
              <ProtectedRoute requiredModuleId={502}>
                <NewBenefit />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/programa-de-fidelidade/editar-beneficio" 
            element={
              <ProtectedRoute requiredModuleId={502}>
                <NewBenefit />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/programa-de-fidelidade/visualizar-relatorios" 
            element={
              <ProtectedRoute requiredModuleId={502}>
                <ViewReport />
              </ProtectedRoute>
            } 
          />

          {/* Pontos */}
          <Route 
            path="/pontos" 
            element={
              <ProtectedRoute requiredModuleId={501}>
                <Points />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/programa-de-fidelidade/incluir-pontos" 
            element={
              <ProtectedRoute requiredModuleId={501}>
                <NewPoints />
              </ProtectedRoute>
            } 
          />

          {/* Proprietários */}
          <Route 
            path="/proprietarios" 
            element={
              <ProtectedRoute requiredModuleId={2}>
                <Owners />
              </ProtectedRoute>
            } 
          />

          {/* Gestão de Usuários */}
          <Route 
            path="/gestao-usuarios" 
            element={
              <ProtectedRoute requiredModuleId={6}>
                <UserManagement />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/novo-usuario" 
            element={
              <ProtectedRoute requiredModuleId={6}>
                <NewUser />
              </ProtectedRoute>
            } 
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
