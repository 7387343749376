import { Typography } from "@mui/material";
import { useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import * as Styled from "./styles";
import { IUpload } from "./types";

interface IUploadContractProps extends IUpload {
  error?: unknown;
  fileUrl?: string;
  disabled?: boolean;
}

const UploadContract: React.FC<IUploadContractProps> = ({
  onUpload,
  hasFile,
  error,
  disabled,
  fileUrl,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList || fileList.length === 0) return;
  
    const selectedFile = fileList[0];
  
    if (selectedFile.type !== "application/pdf") {
      alert("Apenas arquivos PDF são permitidos.");
      return;
    }
  
    setFile(selectedFile);
    onUpload([selectedFile]);
  };
  
  const removeFile = () => {
    setFile(null);
    onUpload([]); 
  };

  return (
    <div
      style={{ display: "flex", gap: "8px", alignItems: "center" }}
      className="font-main"
    >
      <Styled.PreviaContainer onClick={() => inputRef.current?.click()}>
        <Styled.PreviaDescription>
          <input
            disabled={disabled ?? false}
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            onChange={handleFileChange}
          />
          <Typography
            variant="body2"
            color="#F28E00"
            className="font-main"
            sx={{ fontFamily: "Poppins" }}
          >
            Escolher arquivo
          </Typography>
        </Styled.PreviaDescription>
      </Styled.PreviaContainer>

      <Styled.FileUpload>
        {file ? (
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <FaCheck />
            <Typography variant="body2">{file.name}</Typography>
            <AiFillCloseCircle
              color="#E86060"
              onClick={removeFile}
              data-cy="cancel-file-btn"
            />
          </div>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontFamily: "Poppins" }}
          >
            Nenhum arquivo selecionado
          </Typography>
        )}
      </Styled.FileUpload>
    </div>
  );
};

export default UploadContract;
