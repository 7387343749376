import React from "react"; 
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { z } from "zod";
import { useForm, Controller, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import Header from "../../../../components/Header";
import Input from "../../../../components/Input";
import IconSelector from "../../../../components/IconSelector";
import Button from "../../../../components/Button";
import CustomToast from "../../../../components/Toast";
import BreadcrumbsPage from "../../../../components/Breadcrumbs";
import ResponsibleUsersMultiSelect, {
  ResponsibleUser
} from "../../../../components/ResponsibleUsersSelect";

import channel from "../../../../api/channel/channel";
import icon1 from "../../../../assets/icons/main-icon.svg";

export default function NewChannel() {
  const navigate = useNavigate();
  const location = useLocation();

  const isEdit = location.state?.isEdit;
  const dataEdit = location.state?.data;

  const [listUsersResponsible, setListUsersResponsible] = useState<ResponsibleUser[]>([]);
  const [selectedIconFile, setSelectedIconFile] = useState<File | null>(null);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const newChannelCreateSchema = z.object({
    name: z.string().min(1, "Nome é obrigatório"),
    responsibleUsers: z
      .array(
        z.object({
          Id: z.number(),
          Name: z.string(),
          HasPermission: z.number().optional()
        })
      )
      .optional()
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: {
      responsibleUsers: [] 
    }
  });

  const selectedUsers = useWatch({ control, name: "responsibleUsers" });

  const handleToastSuccess = () => (
    <CustomToast message="Canal cadastrado com sucesso" type="success" />
  );

  const handleToastSuccessEdit = () => (
    <CustomToast message="Canal atualizado com sucesso" type="success" />
  );

  async function handleSubmitForm(data: NewChannelsSchema) {
    setIsSubmitting(true);
    try {
      if (!selectedIconFile) {
        const response = await fetch(icon1);
        const blob = await response.blob();
        setSelectedIconFile(new File([blob], "icon-default.png", { type: blob.type }));
      }

      const userIds = data.responsibleUsers?.map(u => u.Id.toString()) || [];
      const id = dataEdit?.Id;

      if (isEdit) {
        await channel.editChannel(id, selectedIconFile, data.name, userIds);
        handleToastSuccessEdit();
        navigate("/Canais");
      } else {
        await channel.postNewChannel(selectedIconFile, data.name, userIds);
        handleToastSuccess();
        navigate(-1);
      }
    } catch (error) {
      console.error("Erro ao submeter o formulário:", error);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function fetchUsers() {
    try {
      if (isEdit && dataEdit?.Id) {
        const response = await channel.getAdminsChannel(dataEdit.Id);
        const users = (response as unknown as ResponsibleUser[]) || [];
        setListUsersResponsible(users);

        const alreadySelected = users.filter((user) => user.HasPermission === 1);
        setValue("responsibleUsers", alreadySelected);
      } else {
        const response = await channel.getResponsibleUsers();
        if (response) {
          setListUsersResponsible(response as unknown as ResponsibleUser[]);
        }
      }
    } catch (error) {
      console.error("Erro ao listar usuários responsáveis", error);
    } finally {
      setLoadingUsers(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (isEdit && dataEdit) {
      setValue("name", dataEdit.Title);
    }
  }, [isEdit, dataEdit, setValue]);

  useEffect(() => {
    async function fetchDefaultIcon() {
      const response = await fetch(icon1);
      const blob = await response.blob();
      const defaultFile = new File([blob], "icon-default.png", { type: blob.type });
      setSelectedIconFile(defaultFile);
    }
    fetchDefaultIcon();
  }, []);

  const handleIconSelect = (icon: React.ReactNode) => {
    if (React.isValidElement(icon) && icon.props?.src) {
      fetch(icon.props.src)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "icon.png", { type: blob.type });
          setSelectedIconFile(file);
        })
        .catch((error) => {
          console.error("Erro ao buscar o ícone", error);
        });
    } else {
      console.error("Ícone selecionado inválido.");
    }
  };

  return (
    <div className="flex flex-col justify-between h-screen pl-10">
      <div>
        <div className="flex flex-col w-full gap-2 pt-4">
          <BreadcrumbsPage />
          <div className="w-1/2">
            <Header
              title={isEdit ? "Editar canal" : "Cadastrar canal"}
              arrowIcon={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
          {loadingUsers ? (
            <p>Carregando usuários...</p>
          ) : (
            <form id="my-form" className="flex flex-col gap-6" onSubmit={handleSubmit(handleSubmitForm)}>
              <div className="flex gap-4">
                <IconSelector onIconSelect={handleIconSelect} initialIconUrl={dataEdit?.ChannelIcon} />
                <div className="w-full">
                  <Input
                    label="Nome"
                    type="text"
                    placeholder="Digite o nome do canal"
                    register={register("name")}
                    errors={errors.name}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-4 rounded-lg">
                <Controller
                  name="responsibleUsers"
                  control={control}
                  render={({ field }) => (
                    <ResponsibleUsersMultiSelect
                      label="Usuários responsáveis"
                      data={listUsersResponsible}   
                      value={field.value || []}     
                      onChange={field.onChange}     
                      errors={errors.responsibleUsers}
                      message="Campo obrigatório"
                      keyField="Id"
                      labelField="Name"
                    />
                  )}
                />
                {selectedUsers && selectedUsers.length > 0 ? (
                  <div>
                    <p>{selectedUsers.length} usuário(s) selecionado(s)</p>
                  </div>
                ) : (
                  <div className="bg-[#F4F5F7] p-2 px-4 rounded-md text-[#404040]">
                    <p>Nenhum usuário selecionado</p>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </div>

      <div className="flex justify-between mt-auto shadow-custom p-10">
        <Button
          title="Voltar"
          onClick={() => navigate(-1)}
          backGroundColor={"#FCFCFA"}
          color={"#F28E00"}
          borderRadius={8}
        />
        <Button
          title={isSubmitting ? "Carregando..." : isEdit ? "Salvar canal" : "Cadastrar canal"}
          disabled={isSubmitting}
          backGroundColor={"#F28E00"}
          color={"#FAFAFC"}
          borderRadius={8}
          type="submit"
          form="my-form"
        />
      </div>
    </div>
  );
}
