import ky from "ky";
import { BenefitDTO } from "./dto/benefit-paginated.dto";

const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

class BenefitsAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async postNewBenefit(
    totalPoints: number,
    numberOfUnits: number,
    description: string,
    file: any,
    name: string
  ): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    const formData = new FormData();
      formData.append("name", name);
      formData.append("totalPoints", totalPoints.toString());
      formData.append("numberOfUnits", numberOfUnits.toString());
      formData.append("description", description);
      formData.append("file", file);
      formData.append("networkUnitId", networkUnitId);

      const headers = await this.getHeaders();
      delete headers["Content-Type"];

      await ky.post(`${API_URL}benefits`, {
        body: formData,
        headers,
      });
      return;
  }

  public async updateBenefits(
    idBenefit: number,
    active: boolean,
    totalPoints: number,
    numberOfUnits: number,
    description: string,
    file: string,
    name: string
  ): Promise<unknown> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }

    const dataBody = {
      name: name,
      totalPoints: totalPoints,
      numberOfUnits: numberOfUnits,
      description: description,
      file: file,
      networkUnitId: networkUnitId,
      Active: active,
    };

    try {
      const headers = await this.getHeaders();
      await ky.put(`${API_URL}benefits/${idBenefit}`, {
        json: dataBody,
        headers,
      });
      return;
    } catch (error) {
      console.error("Erro ao cadastrar novo beneficio", error);
      throw error;
    }
  }

  public async getBenefit(
    active: boolean | string,
    name: string,
    pageSize: number,
    offSet: number
  ): Promise<BenefitDTO[]> {
    const networkUnitId = localStorage.getItem("selectedNetwork");

    if (!networkUnitId) {
      throw new Error("Network Unit ID não encontrado no localStorage.");
    }
    let dataBody;
    if (active === "all") {
      dataBody = {
        pageSize,
        offSet,
        name,
        networkUnitId,
      };
    } else {
      dataBody = {
        pageSize,
        offSet,
        active,
        name,
        networkUnitId,
      };
    }

    try {
      const headers = await this.getHeaders();
      const response = await ky.post(
        `${API_URL}benefits/paginated`,
        {
          json: dataBody,
          headers,
        }
      );

      const data: BenefitDTO[] = await response.json();

      return data;
    } catch (error) {
      console.error("Erro ao buscar benefícios:", error);
      throw error;
    }
  }

  public async deleteBenefit(id: number): Promise<void> {
    const headers = await this.getHeaders("application/json");
  
    try {
      await ky.delete(`${API_URL}benefits/${id}`, {
        headers,
      });
    } catch (error) {
      console.error("Erro ao excluir benefício:", error);
      throw error;
    }
  }
  
}

export default new BenefitsAPI();
