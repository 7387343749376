import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import UserApi, { IPermission } from "../../api/user/user";
import NotAuthorized from "../NotAuthorized";
import { Spinner } from "../Spinner";

interface ProtectedRouteProps {
  requiredModuleId: number;
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredModuleId, children }) => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  useEffect(() => {
    async function checkPermission() {
  
      const networkUnitId = localStorage.getItem("selectedNetwork");
      const userId = localStorage.getItem("userId");

      if (!networkUnitId || !userId) {
        console.error("Faltando selectedNetwork ou userId no localStorage");
        setHasPermission(false);
        return;
      }

      try {
        const permissions: IPermission[] = await UserApi.getUserPermissions(networkUnitId, userId);
        
        const permitted = permissions.some(
          (p) => Number(p.AccessModuleId) === requiredModuleId && p.Active === true
        );
        
        setHasPermission(permitted);
      } catch (error) {
        console.error("Erro ao verificar permissões:", error);
        setHasPermission(false);
      }
    }
    checkPermission();
  }, [requiredModuleId]);

  if (hasPermission === null) {
    return <Spinner />;
  }

  if (!hasPermission) {
    return <NotAuthorized />;
  }

  return children;
};

export default ProtectedRoute;
