/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  OutlinedInput,
  Chip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface ResponsibleUser {
  Id: number;
  Name: string;
  HasPermission?: number;
}

interface ResponsibleUsersMultiSelectProps {
  label?: string;
  data: ResponsibleUser[];                
  value: ResponsibleUser[];               
  onChange: (selected: ResponsibleUser[]) => void;
  
  style?: React.CSSProperties;
  keyField?: keyof ResponsibleUser;
  labelField?: keyof ResponsibleUser;
  singleSelect?: boolean;

  errors?: any;
  message?: string;
}

const ResponsibleUsersMultiSelect: React.FC<ResponsibleUsersMultiSelectProps> = ({
  label = "Usuários responsáveis",
  data = [],
  value = [],                     
  onChange,
  style,
  keyField = "Id",
  labelField = "Name",
  singleSelect = false,
  errors,
  message = "Campo obrigatório",
}) => {
  
  const handleChange = (event: any) => {
    const { value: changedValue } = event.target;
    let newSelected = Array.isArray(changedValue) ? [...changedValue] : [changedValue];

    if (singleSelect) {
      newSelected =
        newSelected.length > 0 ? [newSelected[newSelected.length - 1]] : [];
    } else {
      newSelected = newSelected.filter(
        (val, index, self) =>
          index === self.findIndex((t) => t[keyField] === val[keyField])
      );
    }

    onChange(newSelected);
  };

  const isSelected = (item: ResponsibleUser) =>
    value.some((sel) => sel[keyField] === item[keyField]);

  const handleDelete = (valueToDelete: ResponsibleUser) => () => {
    const newSelected = value.filter(
      (v) => v[keyField] !== valueToDelete[keyField]
    );
    onChange(newSelected);
  };

  return (
    <div className="flex flex-col gap-2 font-main w-full">
      {label && <label className="font-medium text-[#404040]">{label}</label>}

      <Select
        multiple={!singleSelect}
        value={value as any}          
        onChange={handleChange}
        input={<OutlinedInput label="Select" />}
        sx={{ width: "100%", ...style }}
        renderValue={(selectedItems: any) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selectedItems.map((item: ResponsibleUser) => (
              <Chip
                key={String(item[keyField])}
                label={String(item[labelField])}
                deleteIcon={
                  <CloseIcon onMouseDown={(e) => e.stopPropagation()} />
                }
                onDelete={handleDelete(item)}
              />
            ))}
          </Box>
        )}
      >
        {data.map((item) => (
          <MenuItem
            key={String(item[keyField])}
            value={item as any}
          >
            <Checkbox checked={isSelected(item)} />
            <ListItemText primary={String(item[labelField])} />
          </MenuItem>
        ))}
      </Select>

      {errors && <p className="text-red-500 text-sm">{message}</p>}
    </div>
  );
};

export default ResponsibleUsersMultiSelect;
